import React, { useEffect } from "react"
import "./index.scss"

const BasicContent = ({children}) => {
  
  return (
    <div className="basic-content">
      {children}
    </div>
  )
}

export default BasicContent
