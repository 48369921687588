import React from "react"
import "./index.scss"

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false)

  return (
    <div className="accordion-wrapper">
      <div className="accordion-toggle" onClick={() => setOpen(!isOpen)}>
        {isOpen && (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 40.85 40.85"
          >
            <circle fill="#9C69AC" cx="20.43" cy="20.43" r="20.43" />
            <rect
              x="7.25"
              y="17.36"
              fill="#ffffff"
              width="26.35"
              height="6.14"
            />
          </svg>
        )}
        {!isOpen && (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 40.85 40.85"
          >
            <circle fill="#9C69AC" cx="20.43" cy="20.43" r="20.43" />
            <rect
              x="7.25"
              y="17.36"
              fill="#ffffff"
              width="26.35"
              height="6.14"
            />
            <rect
              x="7.25"
              y="17.36"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 40.8501 9.130474e-13)"
              fill="#ffffff"
              width="26.35"
              height="6.14"
            />
          </svg>
        )}
      </div>
      <div
        className="accordion-title__wrapper"
        onClick={() => setOpen(!isOpen)}
      >
        <div className={`accordion-title ${isOpen ? "open" : ""}`}>{title}</div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Accordion
